<template>
	<svg width="24" height="24" viewBox="-4 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M9.69789 2.2132C9.44243 2.04116 9.13244 1.96948 8.82739 2.01193C8.52233 2.05438 8.24369 2.20795 8.04489 2.4432L7.96289 2.5522L4.21289 8.1162C4.06042 8.34283 3.98638 8.61316 4.00209 8.88585C4.01781 9.15854 4.1224 9.41858 4.29989 9.6262L4.39689 9.7282L9.14689 14.1642C9.38055 14.3831 9.68931 14.5041 10.0095 14.502C10.3297 14.5 10.6369 14.3752 10.8678 14.1533C11.0986 13.9314 11.2355 13.6294 11.2503 13.3095C11.265 12.9897 11.1564 12.6764 10.9469 12.4342L10.8529 12.3372L6.88289 8.6282L10.0359 3.9482C10.2077 3.69282 10.2792 3.38305 10.2367 3.07823C10.1943 2.7734 10.0409 2.49495 9.80589 2.2962L9.69789 2.2132Z"
			fill="currentColor"
		/>
	</svg>
</template>
